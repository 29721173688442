import find from 'lodash/find';

const getCookie = (name: string) => {
  if (typeof document == 'undefined') return '';
  const cookieArray = document.cookie.split(';');
  const cookie = find(cookieArray, (cookie) => cookie.includes(`${name}=`));
  if (cookie) {
    return cookie.replace(`${name}=`, '').trim();
  }
  return '';
};

const setCookie = (
  name: string,
  value: string,
  expires: Date | 'Session',
  path = '/',
) => {
  document.cookie = `${name}=${value}; expires=${
    expires == 'Session' ? expires : expires.toUTCString()
  }; path=${path}`;
};

const addOneHourCookie = (name: string, value: string) => {
  const oneHourFromNow = new Date();
  const hours = oneHourFromNow.getHours();
  oneHourFromNow.setHours(hours + 2);
  setCookie(name, value, oneHourFromNow);
};

const removeCookie = (name: string) => {
  if (getCookie(name)) {
    document.cookie = name + '=; max-age=0; path=/';
  }
};
const CAREER_TO_ADD = 'careerToAdd';
const PROGRAM_TO_ADD = 'programToAdd';
const JOB_TO_ADD = 'jobToAdd';
const RESOURCE_TO_ADD = 'resourceToAdd';
const WELCOME_PATH_DATA = 'welcomePathData';
const WORKSTYLE_RESULTS = 'workStyleResults';
const CAREER_COACHING_DATA = 'career-coaching-form-data';
const LOCATION_DATA = 'location-data';
export {
  getCookie,
  setCookie,
  addOneHourCookie,
  removeCookie,
  CAREER_TO_ADD,
  PROGRAM_TO_ADD,
  RESOURCE_TO_ADD,
  WELCOME_PATH_DATA,
  WORKSTYLE_RESULTS,
  CAREER_COACHING_DATA,
  JOB_TO_ADD,
  LOCATION_DATA,
};
