import project from './project';
import projectEnv from './projectEnv';

const config = {
  NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN: {
    wherewego: {
      staging: 'wherewegostaging.firebaseapp.com',
      production: 'wherewego-68cac.firebaseapp.com',
      development: 'wherewegostaging.firebaseapp.com',
    },
    skillup: {
      staging: 'projectconnectstaging.firebaseapp.com',
      production: 'projectconnect-7c5f7.firebaseapp.com',
      development: 'projectconnectstaging.firebaseapp.com',
    },
  },
  NEXT_PUBLIC_FIREBASE_PROJECT_ID: {
    wherewego: {
      staging: 'wherewegostaging',
      production: 'wherewego-68cac',
      development: 'wherewegostaging',
    },
    skillup: {
      staging: 'projectconnectstaging',
      production: 'projectconnect-7c5f7',
      development: 'projectconnectstaging',
    },
  },
  NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET: {
    wherewego: {
      staging: 'wherewegostaging.appspot.com',
      production: 'wherewegostaging.appspot.com',
      development: 'wherewegostaging.appspot.com',
    },
    skillup: {
      staging: 'projectconnectstaging.appspot.com',
      production: 'projectconnect-7c5f7.appspot.com',
      development: 'projectconnectstaging.appspot.com',
    },
  },
  NEXT_PUBLIC_FIREBASE_CLIENT_EMAIL: {
    wherewego: {
      staging:
        'firebase-adminsdk-u0ewv@wherewegostaging.iam.gserviceaccount.com',
      production:
        'firebase-adminsdk-o6top@wherewego-68cac.iam.gserviceaccount.com',
      development:
        'firebase-adminsdk-u0ewv@wherewegostaging.iam.gserviceaccount.com',
    },
    skillup: {
      staging:
        'firebase-adminsdk-8mw94@projectconnectstaging.iam.gserviceaccount.com',
      production:
        'firebase-adminsdk-m9qs1@projectconnect-7c5f7.iam.gserviceaccount.com',
      development:
        'firebase-adminsdk-8mw94@projectconnectstaging.iam.gserviceaccount.com',
    },
  },
};

const NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN =
  config.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN[project][projectEnv];
const NEXT_PUBLIC_FIREBASE_PROJECT_ID =
  config.NEXT_PUBLIC_FIREBASE_PROJECT_ID[project][projectEnv];
const NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET =
  config.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET[project][projectEnv];
const NEXT_PUBLIC_FIREBASE_CLIENT_EMAIL =
  config.NEXT_PUBLIC_FIREBASE_CLIENT_EMAIL[project][projectEnv];

export {
  NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  NEXT_PUBLIC_FIREBASE_CLIENT_EMAIL,
};
