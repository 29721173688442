import {
  createContext,
  Dispatch,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { UserInfo } from '../../components/shared/LocationFinder';
import useMyRouter from '../hooks/myRouter';
import isJSON from '../fns/isJSON';
import { getCookie, LOCATION_DATA, setCookie } from '../cookies';
import { useAuth } from './authProvider';
import useUpdateUser from '../hooks/updateUser';
import { isEmpty, isEqual } from 'lodash';
import { parse } from 'cookie';
import Banner from '../models/banners.model';
import { useQuery } from '@tanstack/react-query';
import getRegionDataRequest from '../api/getRegionDataRequest';
import Photo from 'wwg-utilities/dist/collections/photo';


export type Region = {
  slug: string; // slugified url version e.g. bay-area
  name: string; // reg name e.g. Bay Area
  abbrev: string; // e.g. Los Angeles -> L.A.
  location: string; // e.g. San Francisco, LA
  banners: Banner[];
  partnerRegionURL?: string;
  partners?: { logo: Photo, url: string }[];
  promotionText?: string;
  regionPreFooterText?: string;


};
const RegionContext = createContext<{
  setRegion: Dispatch<Region>;
  region: Region;
  userLocationInfo?: UserInfo | null,
  cookie?: UserInfo,
  setUserLocationInfo: Dispatch<UserInfo | null>,
}>({
  setRegion: (_region: Region) => { },
  region: {} as Region,
  userLocationInfo: {} as UserInfo,
  cookie: undefined,
  setUserLocationInfo: (_info: UserInfo | null) => { },
});

export default function RegionProvider({ children, serverCookie, serverRegion }: any) {

  // This will fetch the cookie from the server so that location
  // data can be stored for the user's session
  // while also allowing it to be implemented on first render
  const cookie = useMemo(() => {
    if (typeof window === 'undefined') {
      // Accessing server cookies
      const cookies = parse(serverCookie || '');
      const locationDataCookie = cookies[LOCATION_DATA];
      return isJSON(locationDataCookie) ? JSON.parse(locationDataCookie) : null;
    }

    // Accessing client cookies
    return isJSON(getCookie(LOCATION_DATA))
      ? JSON.parse(getCookie(LOCATION_DATA))
      : null;
  }, []);



  const { seeker } = useAuth();
  const [region, setRegion] = useState({} as Region & { initial?: boolean });
  const [userLocationInfo, setUserLocationInfo] = useState<UserInfo | null>(cookie || null) // initialize userLocationInfo with the cookie
  const { navToSelectedRegion, router } = useMyRouter()
  const { handleUpdateUser } = useUpdateUser();


  //  Load up the data for the national catalog
  const { data: everywhereRegion } = useQuery(['everywhere', 'region'], () => {
    return getRegionDataRequest('Everywhere')
  })

  // If there is no region set, initialize to the everywhere catalog
  useEffect(() => {
    if (!isEmpty(serverRegion) && !cookie && !seeker) {
      setRegion(serverRegion) // for first time users who arrive via a direct link to a region (like through an ad), initialize region to match url
    } else if (isEmpty(region) && everywhereRegion && !seeker) {

      setRegion({ ...everywhereRegion, initial: true }) // otherwise, initialize it to everywhere
    }


  }, [region, everywhereRegion, seeker])

  // This syncs userLocationInfo cookies with the current value
  // if they are not already equal
  useEffect(() => {
    if (
      userLocationInfo && !isEqual(userLocationInfo, cookie)
    ) {
      setCookie(LOCATION_DATA, JSON.stringify(userLocationInfo), 'Session');
    }
  }, [userLocationInfo]);


  useEffect(() => {
    if (seeker && userLocationInfo && userLocationInfo?.location?.place_id && seeker?.location?.place_id != userLocationInfo.location.place_id) {
      handleUpdateUser(seeker.uid as string, { ...seeker, ...userLocationInfo });
    }
  }, [userLocationInfo])

  useEffect(() => {
    if (region?.slug && userLocationInfo?.location?.place_id && router.query[':region'] != region.slug && !region.initial) {
      navToSelectedRegion(region.slug)
    }
  }, [region])

  return (
    <RegionContext.Provider
      value={{
        region,
        userLocationInfo,
        cookie,
        setUserLocationInfo,
        setRegion
      }}
    >
      {children}
    </RegionContext.Provider>
  );
}

export const useRegion = () => {
  return useContext(RegionContext);
};
