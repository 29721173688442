export enum AuthType {
  Login = 'login',
  Register = 'register',
  ResetPassword = 'reset-password',
	RecoverOldAccount = 'recover',
  None = ''
}


export enum RegistrationType {
  Email = 'email',
  Phone = 'phone',
  Google = 'google',
}

export enum LoginType {
  Phone = "phone",
  Email = "email",
  Google = "google"
}
export enum AlertState {
  Success ='success',
  Error = 'error'
}


export enum ConnectionStatus {
	Saved = "Saved",
	ClickededGetStarted = 'Clicked Get Started',
}


