import { useRouter } from 'next/router';
import { useEffect } from 'react';
import gaDataLayerEvent from './gaDataLayerEvent';

const useGASearch = () => {
  const {
    query: { search },
  } = useRouter();

  useEffect(() => {
    if (search) {
      gaDataLayerEvent({
        event: 'search',
        search: { term: search },
      });
    }
  }, [search]);
};
export default useGASearch;
