import 'swiper/css';
import 'swiper/css/autoplay';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/globals.css';
import 'nprogress/nprogress.css';
import { useCallback, useEffect, useState } from 'react';
import type { AppContext, AppProps } from 'next/app';
import { ToastContainer } from 'react-toastify';
import Router from 'next/router';
import NProgress from 'nprogress';
import dynamic from 'next/dynamic';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import useScrollPercentage from '../lib/providers/scrollPercentage';
import project, { isSkillup, isWhereWeGo } from '../lib/config/project/project';
import { useAuthType } from '../lib/providers/authTypeState';
import { AuthType } from '../lib/enums';
import useGASort from '../lib/hooks/GA/gaSort';
import useGAFilters from '../lib/hooks/GA/gaFilters';
import useGASearch from '../lib/hooks/GA/gaSearch';
import RegionProvider, { Region } from '../lib/providers/regionProvider';
import getRegionDataRequest from '../lib/api/getRegionDataRequest';


const BrowserOnly = dynamic(() => import('../components/shared/BrowserOnly'), { ssr: false }) as any;

// Dynamically loaded components
const AuthProvider = dynamic(() => import('../lib/providers/authProvider'));
const AuthStateProvider = dynamic(
  () => import('../lib/providers/authTypeState'),
);
const PasswordResetForm = dynamic(
  () => import('../components/Auth/PasswordResetForm'),
);
const ProjectHead = dynamic(
  () => import('../lib/config/project/components/ProjectMeta'),
);
const ProjectScripts = dynamic(
  () => import('../lib/config/project/components/ProjectScripts'),
);
const AuthModal = dynamic(() => import('../components/Auth/AuthModal'));

const ProjectRegisterForm = dynamic(
  () => import('../lib/config/project/components/ProjectRegisterForm'),
);
const ProjectLoginForm = dynamic(
  () => import('../lib/config/project/components/ProjectLoginForm'),
);


// NProgress config
Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

const MyApp = ({ Component, pageProps, cookies, serverRegion }: AppProps & { cookies: string, serverRegion: Region }) => {
  useScrollPercentage();
  useGASort();
  useGAFilters();
  useGASearch();
  return (
    <QueryClientProvider client={queryClient}>
      <AuthStateProvider><a href="http://"></a>
        <AuthProvider>
          <RegionProvider serverCookie={cookies} serverRegion={serverRegion}>
            <ProjectHead />
            <Component {...pageProps} />

            <BrowserOnly>
              <Auth />
              <ToastContainer
                hideProgressBar={true}
                className={project}
                position="bottom-right"
              />
              <ProjectScripts />
            </BrowserOnly>
          </RegionProvider>
        </AuthProvider>
      </AuthStateProvider>
    </QueryClientProvider>
  );
};

const Auth = () => {
  const { authType, setAuthType } = useAuthType();
  const [isOpen, setIsOpen] = useState(false);

  const closeModal = useCallback(() => {
    setIsOpen(false);
    setTimeout(() => {
      setAuthType(AuthType.None);
    }, 500);
    return false as false;
  }, []);
  useEffect(() => {
    if (authType == AuthType.None) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }

    () => setIsOpen(false);
  }, [authType]);

  return (
    <>
      {authType != AuthType.None && (
        <AuthModal
          isOpen={isOpen}
          height={''}
          width={
            authType == AuthType.Register && isSkillup
              ? 'w-144 max-w-full'
              : authType == AuthType.Register && isWhereWeGo
                ? 'lg:w-[57rem] w-full max-w-full'
                : 'max-w-full w-96'
          }
          title={''}
          description={''}
          closeModal={closeModal}
        >
          {authType == AuthType.Login && (
            <ProjectLoginForm closeModal={closeModal} />
          )}
          {authType == AuthType.Register && (
            <ProjectRegisterForm closeModal={closeModal} />
          )}

          {authType == AuthType.ResetPassword && <PasswordResetForm />}
        </AuthModal>
      )}
    </>
  );
};


MyApp.getInitialProps = async (appContext: AppContext) => {
  const { ctx } = appContext;
  const region = ctx.query[':region'] as string
  let serverRegion = {} as Region;
  if (region != 'all') {
    try {
      serverRegion = await getRegionDataRequest(region)
    } catch (err) {
      serverRegion = {} as Region;
    }
  }

  return { cookies: ctx.req?.headers?.cookie, serverRegion }; // Pass the context to the CustomApp component
};
export default MyApp;
