type ProjectType = 'wherewego' | 'skillup';

const project =
  (process.env.PROJECT as ProjectType) ||
  (process.env.NEXT_PUBLIC_PROJECT as ProjectType);


const isSkillup = project == 'skillup';
const isWhereWeGo = project == 'wherewego';


export default project;
export {isSkillup, isWhereWeGo}
