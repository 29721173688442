import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { deleteUser, User } from 'firebase/auth';
import nookies from 'nookies';

import auth from '../config/firebase';
import { useAuthType } from './authTypeState';
import { AuthType } from '../enums';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import getUserByUIDRequest from '../api/getUserByUIDRequest';
import CompletedTask from 'wwg-utilities/dist/collections/completed-tasks';
import Task from 'wwg-utilities/dist/collections/tasks';
import gaDataLayerEvent from '../hooks/GA/gaDataLayerEvent';
import CareerSeeker from '../models/career-seeker.model';

const AuthContext = createContext<{
  user: User | null;
  seeker: CareerSeeker | null;
  setUser: any;
  setSeeker: any;
  updateSeekerTasks: (task: CompletedTask | undefined) => void;
}>({
  user: null,
  seeker: null,
  setUser: null,
  setSeeker: null,
  updateSeekerTasks: (_task: CompletedTask | undefined) => { },
});

export default function AuthProvider({ children }: any) {
  const [user, setUser] = useState<User | null>(null);
  const [seeker, setSeeker] = useState<CareerSeeker & { init: boolean } | null>(null);
  const [error, setError] = useState({} as any);
  const { authType, setAuthType } = useAuthType();

  useEffect(() => {
    return auth.onIdTokenChanged(async (tokenUser) => {
      // If there is no user, clear all auth items
      handleAuthChange(tokenUser);
    });
  }, []);

  const updateSeekerTasks = (newTask: CompletedTask | undefined) => {
    // Precautions
    if (!newTask || !seeker) return;
    if (!seeker.completedTasks) {
      seeker.completedTasks = [];
    }

    // Check for an already existing task
    const taskExists = seeker?.completedTasks?.find(
      (ct) => ct.task == (newTask.task as Task).id,
    );

    // if task already exists, we don't want to do anything
    // unless we need to delete it
    if (taskExists) {
      if (newTask.isDeleted) {
        // if task exists and new task says to delete,
        // filter out tasks that match new task
        // and reset seeker.completedTasks
        const newTasks = seeker.completedTasks?.filter(
          (ct) => ct.task != (newTask.task as Task).id,
        );
        setSeeker({ ...seeker, completedTasks: newTasks });
        toast.success(
          <p>{`${(newTask.task as Task).title} task marked incomplete`}</p>,
        );
        gaDataLayerEvent({
          event: 'path_interaction',
          path_interaction: `task_incomplete_${(newTask.task as Task).priority
            }`,
          path_text: (newTask.task as Task).title,
          path_selection: undefined,
        });
      }
    } else {
      // otherwise, add the new task
      seeker.completedTasks = [
        ...seeker.completedTasks,
        { ...newTask, task: (newTask.task as Task).id },
      ];
      setSeeker({ ...seeker });

      toast.success(
        <p>{`You've completed the ${(newTask.task as Task).title} task!`}</p>,
      );
      gaDataLayerEvent({
        event: 'path_interaction',
        path_interaction: `task_complete_${(newTask.task as Task).priority}`,
        path_text: (newTask.task as Task).title,
        path_selection: undefined,
      });
    }
  };
  const handleAuthChange = useCallback(
    async (tokenUser: User | null) => {
      if (!tokenUser) {
        setUser(null);
        setSeeker(null);
        nookies.set(undefined, 'token', '', { path: '/' });
        Cookies.set('token', '', { path: '/' });

      } else if (tokenUser) {
        const token = await tokenUser.getIdToken();
        const { email, uid, phoneNumber } = tokenUser;
        try {
          const seeker = await getUserByUIDRequest(
            uid,
            email,
            phoneNumber,
            token,
          );

          setUser(tokenUser);
          setSeeker({ ...seeker, init: true });


          if (authType != AuthType.ResetPassword) {
            setAuthType(AuthType.None);
          }
          nookies.set(undefined, 'token', token, { path: '/' });
          Cookies.set('token', token, { path: '/' });
        } catch (error: any) {
          // TODO set another variable
          setError({ error, user: tokenUser });

          // const isPhoneAuth = tokenUser.providerData[0].providerId == 'phone';
          // if (isPhoneAuth && error.message == 'Phone Login 404') {
          //   deleteUser(tokenUser);
          //   toast.error('User not found.');
          // } else if (isPhoneAuth) {
          //   toast.error('User not found.');
          // }
        }
      }
    },
    [authType],
  );

  useEffect(() => {
    const isPhoneAuth = error.user?.providerData[0]?.providerId == 'phone';

    if (authType == AuthType.Login && error.error && isPhoneAuth) {
      deleteUser(error.user);
      toast.error('User not found.');
    }
  }, [error, user]);

  // force refresh the token every 10 minutes
  useEffect(() => {
    const handle = setInterval(async () => {
      const user = auth.currentUser;
      if (user) await user.getIdToken(true);
    }, 10 * 60 * 1000);

    // clean up setInterval
    return () => clearInterval(handle);
  }, []);
  return (
    <AuthContext.Provider
      value={{
        user,
        seeker,
        setUser,
        setSeeker,
        updateSeekerTasks,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => {
  return useContext(AuthContext);
};
