import CareerSeeker from 'wwg-utilities/dist/collections/career-seeker';
import auth from '../config/firebase';
import apiClient from './axiosClient';

const getUserByUIDRequest = async (
  uid: string,
  email: string | null,
  phone: string | null,
  passedToken?: string,
): Promise<CareerSeeker> => {
  const token = passedToken || (await (auth.currentUser as any)?.accessToken);

  return apiClient
    .get(`seekers/${uid}`, {
      params: { email, phone },
      headers: {
        'Authorization-WWG': `Bearer ${token}`,
      },
    })
    .then((res) => res.data);
};

export default getUserByUIDRequest;
