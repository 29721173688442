import { NextRouter, useRouter } from 'next/router';
import { useCallback } from 'react';
import CareerSeeker from 'wwg-utilities/dist/collections/career-seeker';
import { getRegionSlug } from '../fns/region';
import { useRegion } from '../providers/regionProvider';
import { SingleFilter } from '../types';
export type ReplaceRouterWith =
  | NextRouter
  | {
      pathname?: string;
      query?: any;
    };

const useMyRouter = () => {
  const router = useRouter();
  const { query } = router;
  const {
    region: { slug },
  } = useRegion();

  const goBack = useCallback(() => {
    router.back();
  }, [router]);

  const updateRouter = useCallback(
    (replaceWith: any) => {
      // Always remove region from the query
      // if (replaceWith?.query && replaceWith.query[':region']) {
      //   delete replaceWith.query[':region'];
      // }
   

      router.replace(replaceWith, undefined, { scroll: false }).catch((e) => {
        // workaround for https://github.com/vercel/next.js/issues/37362
        if (!e.cancelled) {
          throw Error('Update Router Error');
        }
      });
    },
    [router,],
  );
  const navToPrefferedRegion = (seeker: CareerSeeker) => {
    const regionSlug = getRegionSlug(seeker.preferredRegion);
    const asPath = router.asPath;
    const hash = asPath.split('#')[1] ? `#${asPath.split('#')[1]}` : '';
    updateRouter({
      pathname: getPathName(regionSlug),
      ...(hash ? { hash } : {}),
    });
  };

  const navToHash = (hash: string) => {
    const query = { ...router.query };
    delete query[':region'];
    updateRouter({
      pathname: getPathName(),
      hash: `#${hash}`,
      query,
      scroll: true,
    });
  };

  const getPathName = (preferredSlug?: string) => {
    return router.pathname.replace('[:region]', preferredSlug || slug);
  };

  const updateQueryParam = (filter: SingleFilter) => {
    const query = { ...router.query };
    query[filter.paramLabel] = filter.value;
    delete query[':region'];
    if (!filter.value) {
      delete query[filter.paramLabel];
    }
    updateRouter({
      pathname: getPathName(),
      query,
      scroll: false,
    });
  };
  const navToSelectedRegion = useCallback(
    (slug: string) => {
      const query = { ...router.query };
      delete query[':region'];
      router.push(
        {
          pathname: getPathName(slug),
          query,
        },
        undefined,
        { shallow: true },
      );
    },
    [router, query],
  );

  return {
    updateRouter,
    router,
    query,
    navToPrefferedRegion,
    navToHash,
    updateQueryParam,
    getPathName,
    goBack,
    navToSelectedRegion,
  };
};

export default useMyRouter;
