import once from 'lodash/once';
import { useRouter } from 'next/dist/client/router';
import { useEffect } from 'react';
import { dataLayerEvent } from '../gtag';

const useScrollPercentage = () => {
  const router = useRouter();
  useEffect(() => {
    if (typeof window != 'undefined' && router.pathname != '/') {
      window.addEventListener('scroll', sendScrollPercentageEvent);
    }
  }, [router]);

  const sendScrollPercentageEvent = () => {
    const scrollPercentage = getCurrentScrollPercentage();
    if (scrollPercentage >= 90) {
      scrollEvent90Once();
    } else if (scrollPercentage >= 75) {
      scrollEvent75Once();
    } else if (scrollPercentage >= 50) {
      scrollEvent50Once();
    } else if (scrollPercentage >= 25) {
      scrollEvent25Once();
    }
  };

  const scrollEvent90 = () => {
    dataLayerEvent('scroll', { key: 'percentage', value: '90%' });
  };
  const scrollEvent90Once = once(scrollEvent90);
  const scrollEvent75 = () => {
    dataLayerEvent('scroll', { key: 'percentage', value: '75%' });
  };
  const scrollEvent75Once = once(scrollEvent75);

  const scrollEvent50 = () => {
    dataLayerEvent('scroll', { key: 'percentage', value: '50%' });
  };
  const scrollEvent50Once = once(scrollEvent50);

  const scrollEvent25 = () => {
    dataLayerEvent('scroll', { key: 'percentage', value: '25%' });
  };
  const scrollEvent25Once = once(scrollEvent25);

  const getCurrentScrollPercentage = (): number => {
    return (
      ((document.documentElement.scrollTop + document.body.scrollTop) /
        (document.documentElement.scrollHeight -
          document.documentElement.clientHeight)) *
      100
    );
  };
};

export default useScrollPercentage;
