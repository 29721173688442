import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import updateSeekerRequest from '../api/updateSeekerRequest';
import { useAuth } from '../providers/authProvider';

const useUpdateUser = () => {
  const { setSeeker } = useAuth();
  const updateUserMutation = useMutation({
    mutationFn: (data: { uid: string; formValues: any }) => {
      return updateSeekerRequest(data.uid, {
        ...data.formValues,
      });
    },
    onSuccess: (data) => {
      ({ data });
      console.log("update user")
      setSeeker(data);
    },
    onError: async (error: any) => {
      toast.error(error.message);
    },
  });

  const handleUpdateUser = async (uid: string, formValues: any) => {
    return updateUserMutation.mutate({
      uid,
      formValues,
    });
  };

  return { handleUpdateUser, isLoading: updateUserMutation.isLoading };
};

export default useUpdateUser;
