import UpdateSeekerDTO from 'wwg-utilities/dist/dtos/update-seeker.dto';
import auth from '../config/firebase';
import { getCookie } from '../cookies';
import apiClient from './axiosClient';

const updateSeekerRequest = async (
  uid: string = '',
  seeker: UpdateSeekerDTO,
) => {
  const token = (await auth.currentUser?.getIdToken()) || getCookie('token');
  return apiClient
    .put(`seekers/${uid}`, seeker, {
      headers: {
        'Authorization-WWG': `Bearer ${token}`,
      },
    })
    .then((res) => res.data);
};

export default updateSeekerRequest;
