import { Catalog } from 'wwg-utilities/dist/catalogs';
import catalogConfig from 'wwg-utilities/dist/config/catalogConfig';
import project from './project';
const catalogs = catalogConfig[project].catalogs;
const defaultCatalog = catalogConfig[project].defaultCatalog;
const EVERYWHERE = {
  name: 'Everywhere',
  slug: 'all',
  abbrev: 'all',
  location: 'United States',
  place_id: '',
  place_name: '',
  coordinates: [],
  initial: true,
  banners: []
};
const newCatalogs = [
  EVERYWHERE,
  ...catalogConfig[project].newCatalogs,
] as Catalog[];
export default catalogs;
export { defaultCatalog, newCatalogs, EVERYWHERE };
