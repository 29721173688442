import * as React from 'react';
import { PropsWithChildren } from 'react';
import {
  createContext,
  useContext,
  useState,
} from 'react';
import { AuthType } from '../enums';

type AuthTypeContext = {
  authType: AuthType;
  setAuthType: React.Dispatch<React.SetStateAction<AuthType>>;
  clearAuthState: () => void;
  setAuthTypeToLogin: () => void;
  setAuthTypeToRegister: () => void;
};
const LocalStateContext = createContext({} as AuthTypeContext);
const LocalStateProvider = LocalStateContext.Provider;

const AuthStateProvider: React.FC<PropsWithChildren<any>> = ({ children }) => {
  const [authType, setAuthType] = useState(AuthType.None);

  const clearAuthState = () => {
    setAuthType(AuthType.None);
  };

  const setAuthTypeToLogin = () => {
    setAuthType(AuthType.Login);
  };

  const setAuthTypeToRegister = () => {
    setAuthType(AuthType.Register);
  };

 


  return (
    <LocalStateProvider
      value={{
        authType,
        setAuthType,
        clearAuthState,
        setAuthTypeToLogin,
        setAuthTypeToRegister,
      }}
    >
      {children}
 
    </LocalStateProvider>
  );
};

export const useAuthType = () => {
  const all = useContext(LocalStateContext);
  return all;
};
export default AuthStateProvider;
