import { useRouter } from 'next/router';
import { useEffect } from 'react';
import gaDataLayerEvent from './gaDataLayerEvent';

const useGASort = () => {
  const {
    query: { sortBy },
  } = useRouter();

  useEffect(() => {
    if (sortBy) {
      gaDataLayerEvent({ event: 'sort', sort: { sort_type: sortBy } });
    }
  }, [sortBy]);
};

export default useGASort;
