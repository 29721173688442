import { Catalog } from 'wwg-utilities/dist/catalogs';
import { newCatalogs } from '../config/project/catalogs';

const getRegionLocation = (region: Catalog) => {
  return region.location;
};

const getRegionName = (slug: string) => {
  const region = newCatalogs.find((cat) => cat.slug === slug);
  const name = region?.name || '';
  return name;
};

const getFullRegion = (slug: string) => {
  return newCatalogs.find((cat) => cat.slug === slug);
};
const getRegionPlaceId = (slug: string) => {
  const region = newCatalogs.find((cat) => cat.slug === slug) as Catalog;
  return region.place_id;
};

const getRegionPlaceName = (slug: string) => {
  const region = newCatalogs.find((cat) => cat.slug === slug) as Catalog;
  return region.place_name;
};

const getRegionSlug  = (name = '') => {
  return newCatalogs.find((cat)=> cat.name == name)?.slug || '';
}

export {
  getRegionLocation,
  getRegionName,
  getFullRegion,
  getRegionPlaceId,
  getRegionPlaceName,
  getRegionSlug
};
