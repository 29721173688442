
export type CustomVariable = {
  key: string;
  value: string;
};

export const dataLayerEvent = (
  event: string,
  customVariable: CustomVariable | CustomVariable[],
) => {
  const gtmWindow = window as any;
  gtmWindow.dataLayer = gtmWindow.dataLayer || [];
    if (Array.isArray(customVariable)) {
      const variables = customVariable.reduce(
        (total: any, current: CustomVariable) => {
          total[current.key] = current.value;
          return total;
        },
        {
          event,
        },
      );
      gtmWindow.dataLayer.push(variables);
    } else {
      gtmWindow.dataLayer.push({
        event,
        [customVariable.key]: customVariable.value,
      });
    }
};
