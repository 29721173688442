import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';
import gaDataLayerEvent from './gaDataLayerEvent';

const filters = [
  'location',
  'hours',
  'careers',
  'careerFields',
  'formats',
  'age',
  'industry',
  'minimum_salary',
  'salary',
  'workStyle',
  'style',
  'location',
  'jobTitle',
  'tags',
  'provider'
];
const filterMap: any = {
  location: 'location',
  hours: 'hours',
  careers: 'careers',
  careerFields: 'industry',
  formats: 'formats',
  age: 'age',
  salary: 'minimum_salary',
  style: 'work_style',
  workStyle: 'work_style',
  industry: 'industry',
  jobTitle: 'job_title',
  tags: 'tags',
  provider: 'provider'
};

const useGAFilters = () => {
  const { query } = useRouter();

  const prevFilters = useRef('');
  useEffect(() => {
    const queryCopy = { ...query };
    delete queryCopy.page;
    delete queryCopy.sortBy;
    const jsonFilters = JSON.stringify(queryCopy);
    if (queryCopy && prevFilters.current != jsonFilters) {
      const no_of_filters_applied = Object.keys(queryCopy).reduce(
        (prev, curr) => {
          if (filters.includes(curr)) {
            const values = (queryCopy[curr] as string)?.split('_');
            prev += values.length;
          }
          return prev;
        },
        0,
      );
      if (no_of_filters_applied) {
        const filter = filters.reduce(
          (prev, curr) => {
            prev = {
              ...prev,
              ...(queryCopy[curr]
                ? { [filterMap[curr]]: queryCopy[curr] }
                : {}),
            };
            return prev;
          },
          {
            no_of_filters_applied,
          },
        );

        gaDataLayerEvent({
          event: 'filter',
          filter,
        });
      }
      prevFilters.current = jsonFilters;
    }
  }, [query]);
};

export default useGAFilters;
